<template>
  <div>

    <base-template
        :title="$tc('supplierInvoice',2)"
        itemView="list"
        :columns="columns"
        :filters="filters"
        :items="incomingInvoices2"
        :context="incomingInvoicesContext"
        :isActionColumnEnabled="true"
        :openViewWindow.sync="openViewWindow"
        :allow-add="allowAdd"
        :allow-add-by-upload="allowAddByUpload"
        :display-custom-fields="displayCustomFields&&(allowAdd||allowAddByUpload)"

        @fetch="$emit('reloadSuppliersInvoices', $event)"
        @selectItem="showDetails"
        @addItem="supplierInvoiceModalShow=true"
        @addItemByUpload="supplierInvoiceByUploadModalShow = true"
    >
      <template #bottomTitle>
        <i v-if="incomingInvoicesContext.custom_fields && displayCustomFields && !allowAdd && !allowAddByUpload">
          {{ currency(incomingInvoicesContext.custom_fields.preTaxAmounts) }} HT /
          {{ currency(incomingInvoicesContext.custom_fields.totalAmounts) }} TTC
        </i>
      </template>

      <!--  =========================== -->
      <!--  ===      Grid view      === -->
      <!--  =========================== -->
      <template v-slot:gridView="{item}">
        <incoming-invoice-card
            :incoming-invoice="item"
            :is-downloadable="false"
            :is-viewable="true"
            :is-payable="allowPayment"

            @click="showDetails(item)"
            @view="showDetails(item)"
        />
      </template>

      <!--  =========================== -->
      <!--  ===      List view      === -->
      <!--  =========================== -->
      <!--      Actions-->
      <template #listView_cell_actions="{item}">
        <div class="text-nowrap">

          <!--          View-->
          <button-view
              @click.native.stop="showDetails(item)"
              v-b-tooltip.hover.left="$t('View')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Edit -->
          <button-edit
              v-if="allowEdit"
              @click.native.stop="editSupplierInvoice(item)"
              v-b-tooltip.hover.left="$t('Edit')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Download-->
          <button-download
              v-b-tooltip.hover.left="$t('Download')"
              @click.native.stop="downloadIncomingInvoice(item)"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Duplicate-->
          <button-duplicate
              v-if="allowAdd"
              v-b-tooltip.hover.left="capitalize($t('duplicate'))"
              @click.native.stop="duplicateIncomingInvoice(item)"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Delete-->
          <button-remove
              v-if="allowRemove"
              v-b-tooltip.hover.left="capitalize($t('delete'))"
              @click.native.stop="deleteIncomingInvoiceAlert(item)"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              :is-disabled="item._linkedElements.length > 0"
              size="sm"
          />

        </div>
      </template>


      <!--  =========================== -->
      <!--  ===     Select view     === -->
      <!--  =========================== -->
      <template #leftViewWindow="{item}">
        <incoming-invoice-card
            :incoming-invoice="item"
            :is-downloadable="false"
            :is-viewable="true"
            :is-selected="item.id==selectedItems[0].id"

            @click="showDetails(item)"
            @view="showDetails(item)"
        />
      </template>
      <template #windowView
                v-if="selectedItems.length">
        <div class="d-flex justify-content-between mb-1">
          <h4>{{ selectedItems[0].billNumber }} -
            {{ selectedItems[0].supplierCompany ? selectedItems[0].supplierCompany._display : '' }}</h4>
          <div>

            <!--          Edit -->
            <button-edit
                v-if="allowEdit"
                @click.native.stop="editSupplierInvoice(selectedItems[0])"
                v-b-tooltip.hover.left="$t('Edit')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />

            <!--          Download-->
            <button-download
                v-b-tooltip.hover.left="$t('Download')"
                @click.native.stop="downloadIncomingInvoice(selectedItems[0])"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />

            <!--          Duplicate-->
            <button-duplicate
                v-if="allowAdd"
                v-b-tooltip.hover.left="capitalize($t('duplicate'))"
                @click.native.stop="duplicateIncomingInvoice(selectedItems[0])"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />

            <!--          Close-->
            <button-close
                @click.native.stop="showDetails(null)"
                v-b-tooltip.hover.left="$t('Close')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />
          </div>
        </div>

        <b-alert
            show
            :variant="incomingInvoicePaymentBannerColor(selectedItems[0])"
        >
          <div class="alert-body d-flex justify-content-between">
            <icon icon="cash-register"
                  class="top-0 my-auto"/>
            <span class="ml-50 w-100 my-auto">
              <span v-if="incomingInvoicePaymentBannerColor(selectedItems[0]) == 'danger'">
                {{ $t('The payment of this supplier invoice is late') }}
              </span>
              <span v-else-if="incomingInvoicePaymentBannerColor(selectedItems[0]) == 'warning'">
                {{ $t('A partial payment has been recorded for this supplier invoice') }}
              </span>
              <span v-else-if="incomingInvoicePaymentBannerColor(selectedItems[0]) == 'success'">
                {{ $t('This supplier invoice is in lettered status') }}
              </span>
              <span v-else>
                {{ $t('This supplier invoice is in Open status') }}
              </span>
            </span>
          </div>
        </b-alert>

        <supplier-invoice-view
            :supplier-invoice="selectedItems[0]"
        />

        <!--        <iframe-->
        <!--            :src="selectedItems[0].receipt.url"-->
        <!--            width="100%"-->
        <!--            height="1192px"-->
        <!--        />-->

        <!--        <view-incoming-invoice-->
        <!--            class="mt-1 invoice-preview"-->
        <!--            :invoice="selectedItems[0]"-->
        <!--        />-->


      </template>
    </base-template>


    <!-- modal supplier invoice by upload-->
    <modal-upload
        :single-file.sync="newSupplierInvoiceFile"
        :document-type="'incomingInvoice'"
        :title="capitalize($tc('invoice'))"
        :is-open.sync="supplierInvoiceByUploadModalShow"
    />

    <!-- modal incoming invoice-->
    <modal-incoming-invoice
        :incoming-invoice.sync="currentSupplierInvoice"
        :title="('id' in currentSupplierInvoice)?$t('EditInvoice'):$t('NewIncomingInvoice')"
        :is-open.sync="supplierInvoiceModalShow"
        @submitValidatedIncomingInvoice="submitValidatedSupplierInvoiceLocal"
    />
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { useSupplierInvoices } from '../../views/purchases/supplierInvoice/useSupplierInvoices'
import { capitalize, currency } from '../../utils/filter'

import BaseTemplate from '../base/Base'
import IncomingInvoiceCard from '../card/IncomingInvoice2'
import ViewIncomingInvoice from './IncomingInvoice.vue'
import ButtonView from '../button/View'
import ButtonDownload from '../button/Download'
import ButtonClose from '../button/Close'
import ButtonEdit from '../button/Edit.vue'
import ButtonDuplicate from '../button/Duplicate.vue'
import ButtonRemove from '../button/Remove.vue'
import ModalIncomingInvoice from '../prompt/IncomingInvoice.vue'
import ModalUpload from '../prompt/Upload.vue'
import SupplierInvoiceView from './SupplierInvoiceView.vue'

import i18n from '../../libs/i18n'
import useAPI from '../../utils/useAPI'
import store from '../../store'

export default {
  components: {
    BaseTemplate,
    IncomingInvoiceCard,
    ViewIncomingInvoice,
    ButtonView,
    ButtonDownload,
    ButtonClose,
    ButtonEdit,
    ButtonDuplicate,
    ButtonRemove,
    ModalIncomingInvoice,
    ModalUpload,
    SupplierInvoiceView,
  },
  props: {
    allowAdd: {
      type: Boolean,
      default: true
    },
    allowAddByUpload: {
      type: Boolean,
      default: true
    },
    allowEdit: {
      type: Boolean,
      default: true
    },
    allowRemove: {
      type: Boolean,
      default: true
    },
    allowPayment: {
      type: Boolean,
      default: true
    },
    disabledFilters: {
      type: Array,
      default: () => []
    },
    defaultSelectedFilters: {
      type: Array,
      default: () => []
    },
    displayCustomFields: {
      type: Boolean,
      default: true
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const columns = ref([
      {
        display: capitalize(i18n.t('supplier')),
        key: 'supplierCompany',
        sortable: true,
        cell: {
          useDisplay: true
        }
      },
      {
        display: capitalize(i18n.t('billNumber')),
        key: 'billNumber',
        sortable: true
      },
      {
        display: capitalize(i18n.t('billingDate')),
        key: 'billingDate',
        sortable: true,
        cell: {
          isDate: true
        }
      },
      {
        display: capitalize(i18n.t('paymentDate')),
        key: 'paymentDate',
        sortable: true,
        cell: {
          isDate: true
        }
      },
      {
        display: capitalize(i18n.t('type')),
        key: 'type',
        sortable: true,
        cell: {
          useIcon: true,
          type: 'supplierInvoiceType',
        }
      },
      {
        display: capitalize(i18n.t('status')),
        key: 'status',
        sortable: false,
        cell: {
          translate: true,
          useBadge: true,
          type: 'paymentStatus',
          // center: true
        }
      },
      {
        display: capitalize(i18n.t('preTaxAmount')),
        key: 'preTaxAmount',
        sortable: true,
        cell: {
          isCurrency: true
        }
      },
      {
        display: capitalize(i18n.t('totalAmount')),
        key: 'totalAmount',
        sortable: true,
        cell: {
          isCurrency: true
        }
      },
    ])
    const filters = ref([
      {
        text: capitalize(i18n.tc('expenseAccount')),
        key: '_filter_isExpenseAccount',
        searchCriteria: [{
          column_name: 'paymentMethod',
          comparator: '=',
          search_text: '5',
        }],
      },
      {
        text: capitalize(i18n.tc('generalExpenses')),
        key: '_filter_isGeneralExpenseType',
        searchCriteria: [{
          column_name: 'type',
          comparator: '=',
          search_text: 'default',
        }],
      },
      {
        text: capitalize(i18n.tc('transport')),
        key: '_filter_isTollType',
        searchCriteria: [{
          column_name: 'type',
          comparator: '=',
          search_text: 'toll',
        }],
      },
      {
        text: capitalize(i18n.tc('accommodation')),
        key: '_filter_isAccommodationType',
        searchCriteria: [{
          column_name: 'type',
          comparator: '=',
          search_text: 'accommodation',
        }],
      },
      {
        text: capitalize(i18n.tc('catering')),
        key: '_filter_isCateringType',
        searchCriteria: [{
          column_name: 'type',
          comparator: '=',
          search_text: 'catering',
        }],
      },
      {
        text: capitalize(i18n.tc('production')),
        key: '_filter_isProductionType',
        searchCriteria: [{
          column_name: 'type',
          comparator: '=',
          search_text: 'production',
        }],
      },
      {
        text: capitalize(i18n.tc('awaitingExport')),
        key: '_filter_isAwaitingExport',
        searchCriteria: [{
          column_name: 'export',
          comparator: 'null',
        }, {
          column_name: 'expensesClaimsExport',
          comparator: 'null',
        }],
        selected: props.defaultSelectedFilters.includes('_filter_isAwaitingExport')
      },
    ])
    const openViewWindow = ref(false)
    const selectedItems = ref([])
    const supplierInvoiceModalShow = ref(false)
    const supplierInvoiceByUploadModalShow = ref(false)
    const currentSupplierInvoice = ref({})
    const newSupplierInvoiceFile = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const {
      incomingInvoices2,
      incomingInvoicesContext
    } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(newSupplierInvoiceFile, (val) => {
      if (val != null) {
        recognizeIncomingInvoice(val)
            .then(response => {
              supplierInvoiceByUploadModalShow.value = false
              newSupplierInvoiceFile.value = null
              currentSupplierInvoice.value = response.data
              supplierInvoiceModalShow.value = true
            })
      }
    })

    watch(supplierInvoiceModalShow, (val) => {
      if (val == false) {
        resetSupplierInvoice()
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      downloadIncomingInvoice,
      recognizeIncomingInvoice,
      removeIncomingInvoice,
      submitValidatedIncomingInvoice,
      incomingInvoicePaymentBannerColor,
    } = useSupplierInvoices()

    // const hasInitialFilter = () => {
    //   return filters.value.some(f => f.selected)
    // }

    const showDetails = (incomingInvoice) => {
      if (incomingInvoice != null) {
        store.dispatch('incomingInvoice2/getIncomingInvoice', incomingInvoice.id)
            .then(response => {
              selectedItems.value = [incomingInvoice]
              selectedItems.value[0] = response
              openViewWindow.value = true
            })

      } else {
        selectedItems.value = []
        openViewWindow.value = false
      }
    }

    const resetSupplierInvoice = () => {
      currentSupplierInvoice.value = JSON.parse(JSON.stringify(store.getters['incomingInvoice2/getEmptyIncomingInvoice']))
    }

    const submitValidatedSupplierInvoiceLocal = () => {
      submitValidatedIncomingInvoice(currentSupplierInvoice.value)
          .then(() => {
            supplierInvoiceModalShow.value = false
            resetSupplierInvoice()
          })
    }

    const editSupplierInvoice = (supplierInvoice) => {
      store.dispatch('incomingInvoice2/getIncomingInvoice', supplierInvoice.id)
          .then(response => {
            currentSupplierInvoice.value = response
            supplierInvoiceModalShow.value = true
          })
    }

    const duplicateIncomingInvoice = (supplierInvoice) => {
      store.dispatch('incomingInvoice2/getIncomingInvoice', supplierInvoice.id)
          .then(response => {
            currentSupplierInvoice.value = response

            delete currentSupplierInvoice.value.id
            delete currentSupplierInvoice.value.logs
            delete currentSupplierInvoice.value.orders
            delete currentSupplierInvoice.value.receipt

            supplierInvoiceModalShow.value = true
          })
    }

    const removeIncomingInvoiceLocal = (incomingInvoice) => {
      removeIncomingInvoice(incomingInvoice)
          .then(response => {
            // if (getRoute().name == 'IncomingInvoice view') {
            //   router.push({ name: 'IncomingInvoices' })
            // } else if (getRoute().name == 'Workflow view') {
            //   let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)
            //   workflow.incomingInvoices = workflow.incomingInvoices.filter(ii => ii.id != incomingInvoice.id)
            // }
          })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    resetSupplierInvoice()

    return {
      // Components
      capitalize,
      currency,

      // Data
      columns,
      filters,
      openViewWindow,
      selectedItems,
      supplierInvoiceModalShow,
      supplierInvoiceByUploadModalShow,
      currentSupplierInvoice,
      newSupplierInvoiceFile,

      // Computed
      incomingInvoices2,
      incomingInvoicesContext,

      // Methods
      showDetails,
      downloadIncomingInvoice,
      submitValidatedSupplierInvoiceLocal,
      incomingInvoicePaymentBannerColor,
      editSupplierInvoice,
      duplicateIncomingInvoice,
      removeIncomingInvoiceLocal,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    deleteIncomingInvoiceAlert (incomingInvoice) {
      this.$bvModal
          .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theIncomingInvoice') }), {
            okVariant: 'danger',
            okTitle: this.capitalize(this.$t('delete')),
            cancelVariant: 'outline-secondary',
            cancelTitle: this.capitalize(this.$t('cancel')),
            centered: true,
          })
          .then(value => {
            if (value == true) {
              this.removeIncomingInvoiceLocal(incomingInvoice)
            }
          })
    }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>