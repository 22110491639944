<template>
  <div class="mt-3">
    <b-overlay :show="isLoading">

      <h1>{{ capitalize($t('expensesAndRevenues')) }}</h1>

      <div class="row">
        <div class="col-md-12 pb-md-0">
          <div class="accountingCashFlow"
               style="border-bottom: 1px solid">
            <!--Graphique-->
            <chart-columns
                :labels="labels"
                :series="series"
                :is-currencies="true"
                :colors="colors"
                :with-legend="false"

                @click="monthSelected"
            />
          </div>

        </div>

        <div class="col-md-12 d-flex justify-content-between">

          <div class="accountingCashFlow"
               style="border-top: 1px solid; border-right: 1px solid">
            <div class="d-flex justify-content-between pb-50">
              <div style="width: 35px; height: 20px;"
                   class="bg-primary"></div>
              <div class="w-100 pl-1">{{ capitalize($tc('revenue', 2)) }}</div>
            </div>
            <div class="d-flex justify-content-between">
              <div style="width: 35px; height: 20px;"
                   class="bg-warning"></div>
              <div class="w-100 pl-1">{{ capitalize($tc('expense', 2)) }}</div>
            </div>
          </div>

          <div class="accountingCashFlow w-100"
               style="border-top: 1px solid; border-left: 1px solid">

            <div class="d-flex justify-content-between">
              <div class="w-100 text-primary">
                <div>{{ $tc('revenue', 2).toUpperCase() }}</div>
                <div>{{ currency(isLoading ? 0 : expensesAndRevenues.revenues) }}</div>
              </div>
              <div class="w-100 text-warning">
                <div>{{ $tc('expense', 2).toUpperCase() }}</div>
                <div>{{ currency(isLoading ? 0 : expensesAndRevenues.expenses) }}</div>
              </div>
            </div>

          </div>
        </div>

      </div>

    </b-overlay>

    <prompt-details
        :is-open.sync="isModalDetailOpen"
        :month-str="monthStr"

        @changeToPrevious="changeMonth('previous')"
        @changeToNext="changeMonth('next')"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency } from '../../../../../utils/filter'
import { isEmptyObject } from '../../../../../utils/utils'

import i18n from '../../../../../libs/i18n'
import moment from 'moment'
import store from '../../../../../store'

import ChartColumns from '../../../../../components/chart/Columns.vue'
import PromptDetails from './components/_promptDetails.vue'

export default {
  components: {
    ChartColumns,
    PromptDetails
  },
  props: {
    expensesAndRevenues: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const colors = ref(['#ff9f43', '#7367f0'])
    const isModalDetailOpen = ref(false)
    const monthStr = ref('')

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isLoading = computed(() => {
      if (isEmptyObject(props.expensesAndRevenues)) {
        return true
      } else {
        return false
      }
    })

    const series = computed(() => {
      if (isEmptyObject(props.expensesAndRevenues) || !labels.value.length) {
        return []
      }

      let expenses = []
      let revenues = []

      labels.value.forEach(monthStr => {
        expenses.push(props.expensesAndRevenues.chart[monthStr].expenses)
        revenues.push(props.expensesAndRevenues.chart[monthStr].revenues)
      })

      return [{
        name: capitalize(i18n.t('expenses')),
        data: expenses
      }, {
        name: capitalize(i18n.t('revenues')),
        data: revenues
      },]

    })

    const labels = computed(() => {
      if (isEmptyObject(props.expensesAndRevenues)) {
        return []
      }

      return Object.keys(props.expensesAndRevenues.chart)
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const monthSelected = ({ position, label }) => {
      monthStr.value = label
      isModalDetailOpen.value = true
    }

    const changeMonth = (previousOrNext) => {
      if (previousOrNext == 'previous') {
        monthStr.value = moment(monthStr.value+'-01').subtract(1,'months').format('YYYY-MM')
      } else {
        monthStr.value = moment(monthStr.value+'-01').add(1,'months').format('YYYY-MM')
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,
      currency,

      // Data
      colors,
      isModalDetailOpen,
      monthStr,

      // Computed
      isLoading,
      labels,
      series,

      // Methods
      monthSelected,
      changeMonth,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>