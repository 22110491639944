<template>
  <div class="mt-3">
    <b-overlay :show="isLoading">

      <h1>{{ capitalize($t('cashFlow')) }}</h1>

      <div class="row">
        <div class="col-md-8 pr-md-0">
          <div class="accountingCashFlow"
               style="border-right: 1px solid">
            <!--Graphique-->

            <chart-line
                :series="series"
                :labels="labels"
                :with-legend="true"
                :colors="colors"
            />

          </div>

        </div>
        <div class="col-md-4 pl-md-0">
          <div class="accountingCashFlow text-right"
               style="border-left: 1px solid">

            <div class="mb-1">
              <label class="mb-0">{{ capitalize($t('openingBalance')) }}</label>
              <div>{{ currency(isLoading ? 0 : treasury.real.openingBalance) }}</div>
            </div>
            <div class="mb-1">
              <label class="mb-0">{{ capitalize($tc('debit', 2)) }}</label>
              <div class="text-danger">{{
                  currency(isLoading ? 0 : (treasury.real.debits + treasury.estimated.debits))
                }}
              </div>
              (
              <span :style="'color :'+colors[0]">{{ currency(isLoading ? 0 : treasury.real.debits) }}</span>
              /
              <span :style="'color :'+colors[1]">{{ currency(isLoading ? 0 : treasury.estimated.debits) }}</span>
              )
            </div>
            <div class="mb-1">
              <label class="mb-0">{{ capitalize($tc('credit', 2)) }}</label>
              <div class="text-success">
                {{ currency(isLoading ? 0 : (treasury.real.credits + treasury.estimated.credits)) }}
              </div>
              (
              <span :style="'color :'+colors[0]">{{ currency(isLoading ? 0 : treasury.real.credits) }}</span>
              /
              <span :style="'color :'+colors[1]">{{ currency(isLoading ? 0 : treasury.estimated.credits) }}</span>
              )
            </div>
            <div>
              <label class="mb-0">{{ capitalize($t('outgoingBalance')) }}</label>
              <div>{{ currency(isLoading ? 0 : treasury.estimated.closingBalance) }}</div>
            </div>

          </div>
        </div>
      </div>

    </b-overlay>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency } from '../../../../utils/filter'
import { isEmptyObject } from '../../../../utils/utils'
import i18n from '../../../../libs/i18n'

import ChartLine from '../../../../components/chart/Line.vue'

export default {
  components: {
    ChartLine
  },
  props: {
    treasury: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const colors = ref(['#7367f0', '#df87f2'])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isLoading = computed(() => {
      if (isEmptyObject(props.treasury)) {
        return true
      } else {
        return false
      }
    })

    const series = computed(() => {
      if (isEmptyObject(props.treasury) || !labels.value.length) {
        return []
      }

      let bankBalance = []
      let estimate = []
      let realKeyValues = props.treasury.real.chart
      let estimateKeyValues = props.treasury.estimated.chart

      labels.value.forEach(monthStr => {
        bankBalance.push((monthStr in realKeyValues) ? realKeyValues[monthStr] : null)
        estimate.push((monthStr in estimateKeyValues) ? estimateKeyValues[monthStr] : null)
      })

      return [{
        name: capitalize(i18n.t('bankBalance')),
        data: bankBalance
      }, {
        name: capitalize(i18n.t('estimate')),
        data: estimate
      },]

    })

    const labels = computed(() => {
      if (isEmptyObject(props.treasury)) {
        return []
      }

      return Object.keys(props.treasury.real.chart).concat(Object.keys(props.treasury.estimated.chart).slice(1))
    })

    const openingBalance = computed(() => {
      if (isEmptyObject(props.treasury)) {
        return 0
      }

      return props.treasury.real.openingBalance
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,
      currency,

      // Data
      colors,

      // Computed
      isLoading,
      series,
      labels,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>