<template>
  <div>
    <b-card
        no-body
        class="invoice-preview-card position-relative"
        v-if="invoice"
    >
      <b-row class="mb-4">
        <b-col md="6">
          <div v-if="myCompany">
            <b-img
                v-if="myCompany.avatar != null"
                :src="myCompany.avatar.url"
                alt="logo"
                style="max-height: 55px"
                class="mb-1"
            />
            <div>
              <b>{{ myCompany.name }}</b>
            </div>
            <div v-if="myCompany.addresses != null">
              {{ myCompany.addresses[0].street }}
              <br/>
              {{ myCompany.addresses[0].zipCode }} {{ myCompany.addresses[0].city }}
              <br/>
              {{ getCountry(myCompany.addresses[0].country) }}
            </div>

          </div>
        </b-col>
        <b-col md="6">
          <div class="text-right">
            <h1>{{ capitalize($tc('supplierInvoice')) }}</h1>

            <div class="mb-3">
              {{ $t('Supplier invoice number:') }} {{ invoice.billNumber }}
            </div>

            <div class="mt-1 mb-3 font-weight-bolder">
              <div>{{ $t('BalanceDue:') }}</div>
              {{ currency(invoice._balance) }}
            </div>
          </div>

          <div class="d-flex justify-content-between mt-1">
            <div>
              <div>{{ $t('BillingDate:') }}</div>
              <div>{{ $t('DueDate:') }}</div>
              <div>{{ $t('PaymentMethod:') }}</div>
            </div>

            <div class="text-right">
              <div>{{ invoice.billingDate|moment('L') }}</div>
              <div>{{ invoice.paymentDate|moment('L') }}</div>
              <div>{{ $t(invoice.paymentMethod.name) }}</div>
            </div>
          </div>

        </b-col>
      </b-row>

      <b-row class="mt-1 mb-2">
        <b-col>
          {{
            $t('Supplier invoice for', { company: invoice.supplierCompany ? invoice.supplierCompany._display : '-' })
          }}
        </b-col>
      </b-row>

      <!--      Products-->
      <b-row class="mt-1">
        <b-col>
          <b-table-lite
              responsive
              :items="invoice.products"
              :fields="productsTableHeader"
              :class="{'mb-0': invoice.products.length == 0}"
          >
            <template #cell(article)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.article.name }}
              </b-card-text>
              <b-card-text class="text-nowrap">
                <div v-html="data.item.article.description"></div>
              </b-card-text>
            </template>

            <template #cell(unitPrice)="data">
              <b-card-text class="">
                {{ currency(data.item.unitPrice) }}
              </b-card-text>
            </template>

            <template #cell(discount)="data">
              <b-card-text
                  v-if="data.item.discount != null"
                  class=""
              >
                <span v-if="data.item.isDiscountPercent">{{ percent(data.item.discount) }}</span>
                <span v-else>{{ currency(data.item.discount) }}</span>
              </b-card-text>
              <b-card-text
                  v-else
                  class=""
              >
                -
              </b-card-text>
            </template>

            <template #cell(preTaxAmount)="data">
              <b-card-text class="">
                {{ currency(data.item.preTaxAmount) }}
              </b-card-text>
            </template>
          </b-table-lite>
          <div
              v-if="invoice.products.length == 0"
              class="mb-2"
          >
            <i>{{ $t('NoArticle') }}</i>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col
            cols="12"
            md="6"
            class="mt-md-0 mt-3"
            order="2"
            order-md="1"
        >
          <h6
              class="mt-1"
              :class="invoice.paymentRelations.length==0?'mb-0':'mb-50'"
          >
            <b>{{ capitalize($tc('payment', 2)) }} :</b>
          </h6>
          <div v-if="invoice.paymentRelations.length == 0">
            <i>{{ $t('NoPayment') }}</i>
          </div>
          <b-table
              v-if="invoice.paymentRelations.length"
              small
              :fields="paymentsTable.fields"
              :items="paymentsTable.items"
          />
        </b-col>

        <b-col
            cols="12"
            md="6"
            class="mt-md-6 d-flex justify-content-end"
            order="1"
            order-md="2"
        >
          <div class="invoice-total-wrapper">


            <!--            Discount-->
            <div
                v-if="invoice.discount"
                class="invoice-total-item"
            >
              <p class="invoice-total-title">
                {{ $t('Discount:') }}
              </p>
              <p class="invoice-total-amount">
                <span v-if="invoice.isDiscountPercent">{{ percent(invoice.discount) }}</span>
                <span v-else>{{ currency(invoice.discount) }}</span>
              </p>
            </div>

            <!--            Pre tax amount-->
            <div class="invoice-total-item">
              <p class="invoice-total-title">
                {{ $t('PreTaxAmount:') }}
              </p>
              <p class="invoice-total-amount">
                {{ currency(invoice.preTaxAmount) }}
              </p>
            </div>

            <!--            Vat-->
            <div class="invoice-total-item">
              <p class="invoice-total-title">
                {{ $t('VAT:') }}
              </p>
              <p class="invoice-total-amount">
                {{ currency(invoice.vat) }}
              </p>
            </div>

            <!--            Total amount-->
            <hr class="my-50">
            <div class="invoice-total-item">
              <p class="invoice-total-title">
                {{ $t('TotalAmount:') }}
              </p>
              <p class="invoice-total-amount">
                {{ currency(invoice.totalAmount) }}
              </p>
            </div>

            <!--            Payments-->
            <hr class="my-50">
            <div class="invoice-total-item">
              <p class="invoice-total-title">
                {{ $t('paymentsMade:') }}
              </p>
              <p class="invoice-total-amount">
                {{ currency(paymentsMade) }}
              </p>
            </div>
            <hr class="my-50">
            <div class="invoice-total-item">
              <p class="invoice-total-title">
                {{ $t('BalanceDue:') }}
              </p>
              <p class="invoice-total-amount">
                {{ currency(parseFloat(invoice.totalAmount) - paymentsMade) }}
              </p>
            </div>

          </div>
        </b-col>


        <!--        <b-col>-->
        <!--          <div class="d-flex justify-content-between">-->
        <!--            <div class="w-100">-->

        <!--              <h6-->
        <!--                  class="mb-50 mt-1"-->
        <!--              >-->
        <!--                <b>{{ capitalize($tc('payment', invoice.paymentRelations.length)) }} :</b>-->
        <!--              </h6>-->
        <!--              <div v-if="invoice.paymentRelations.length == 0">-->
        <!--                <i>{{$t('NoPayment')}}</i>-->
        <!--              </div>-->
        <!--              <b-table-->
        <!--                  v-if="invoice.paymentRelations.length"-->
        <!--                  small-->
        <!--                  :fields="paymentsTable.fields"-->
        <!--                  :items="paymentsTable.items"-->
        <!--              />-->

        <!--            </div>-->
        <!--            <div-->
        <!--                class="font-weight-bolder"-->
        <!--                style="min-width: 220px;"-->
        <!--            >-->
        <!--              <hr class="invoice-spacing my-1">-->

        <!--              <div class="d-flex justify-content-between">-->
        <!--                &lt;!&ndash;                Left&ndash;&gt;-->
        <!--                <div>-->
        <!--                  <div v-if="invoice.discount">{{ $t('Discount:') }}</div>-->
        <!--                  <div>{{ $t('PreTaxAmount:') }}</div>-->
        <!--                  <div>{{ $t('VAT:') }}</div>-->

        <!--                </div>-->

        <!--                &lt;!&ndash;                Right&ndash;&gt;-->
        <!--                <div class="text-right">-->
        <!--                  <div v-if="invoice.discount">-->
        <!--                    <span v-if="invoice.isDiscountPercent">{{ percent(invoice.discount) }}</span>-->
        <!--                    <span v-else>{{ currency(invoice.discount) }}</span>-->
        <!--                  </div>-->
        <!--                  <div>{{ currency(invoice.preTaxAmount) }}</div>-->
        <!--                  <div>{{ currency(invoice.vat) }}</div>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--              <hr class="invoice-spacing my-1">-->

        <!--              <div class="d-flex justify-content-between">-->
        <!--                &lt;!&ndash;                Left&ndash;&gt;-->
        <!--                <div>-->
        <!--                  <div>{{ $t('TotalAmount:') }}</div>-->

        <!--                </div>-->

        <!--                &lt;!&ndash;                Right&ndash;&gt;-->
        <!--                <div class="text-right">-->
        <!--                  <div>{{ currency(invoice.totalAmount) }}</div>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--              <hr class="invoice-spacing my-1">-->

        <!--              <div class="d-flex justify-content-between">-->
        <!--                &lt;!&ndash;                Left&ndash;&gt;-->
        <!--                <div>-->
        <!--                  <div class="text-nowrap">{{ $t('paymentsMade:') }}</div>-->

        <!--                </div>-->

        <!--                &lt;!&ndash;                Right&ndash;&gt;-->
        <!--                <div class="text-right">-->
        <!--                  <div>{{ currency(paymentsMade) }}</div>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--              <hr class="invoice-spacing my-1">-->

        <!--              <div class="d-flex justify-content-between">-->
        <!--                &lt;!&ndash;                Left&ndash;&gt;-->
        <!--                <div>-->
        <!--                  <div>{{ $t('BalanceDue:') }}</div>-->

        <!--                </div>-->

        <!--                &lt;!&ndash;                Right&ndash;&gt;-->
        <!--                <div class="text-right">-->
        <!--                  <div>{{ currency(invoice._balance) }}</div>-->
        <!--                </div>-->
        <!--              </div>-->

        <!--            </div>-->
        <!--          </div>-->
        <!--        </b-col>-->

      </b-row>

    </b-card>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency, percent } from '@/utils/filter'
import { displayPaymentStatus } from '../../utils/utils'

import i18n from '@/libs/i18n'
import store from '../../store'
import useAPI from '../../utils/useAPI'
import moment from 'moment'

export default {
  components: {},
  props: {
    invoice: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const productsTableHeader = ref([
      {
        key: 'article',
        label: i18n.t('article')
      },
      {
        key: 'unitPrice',
        label: i18n.t('unitPrice')
      },
      {
        key: 'quantity',
        label: i18n.t('quantity')
      },
      {
        key: 'discount',
        label: i18n.t('discount')
      },
      {
        key: 'preTaxAmount',
        label: i18n.t('preTaxAmount')
      },

    ])
    const myCompany = ref({})

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const paymentStatusLocal = computed(() => {
      return displayPaymentStatus(props.invoice._paymentStatus)
    })

    const paymentsMade = computed(() => {
      let paymentsMade = 0
      props.invoice.paymentRelations.forEach(pr => paymentsMade += parseFloat(pr.amount))

      return paymentsMade
    })

    const paymentsTable = computed(() => {
      let payments = []
      props.invoice.paymentRelations.forEach(pr => {
        let tmp = {}
        tmp[i18n.t('date')] = moment(pr.paiement.date).format('L')
        tmp[i18n.t('paymentMethod')] = i18n.t(pr.paiement.paymentMethod.name)
        tmp[i18n.t('amount')] = currency(pr.amount)
        payments.push(tmp)
      })

      return {
        fields: [i18n.t('date'), i18n.t('paymentMethod'), i18n.t('amount')],
        items: payments
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { getCountry } = useAPI()

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    store.dispatch('company/getCompany', 1)
        .then(result => {
          myCompany.value = result
        })

    return {
      // Components
      capitalize,
      currency,
      percent,

      // Data
      productsTableHeader,
      myCompany,

      // Computed
      paymentStatusLocal,
      paymentsMade,
      paymentsTable,

      // Methods
      getCountry,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss"
       scoped>
@import "~@/assets/scss/base/pages/invoice.scss";
</style>


<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
