<template>
  <div>
    <b-modal
        v-if="monthStr"
        :title="title"
        v-model="isOpenLocal"
        size="xl"
        scrollable
        hide-footer
    >
      <b-tabs>
        <template #tabs-start>
          <div class="my-auto mr-1">
            <icon
                icon="chevron-circle-left"
                :active-hover="true"
                class="cursor-pointer px-50"

                @click.native.prevent.stop="$emit('changeToPrevious')"
            />
            <icon
                icon="chevron-circle-right"
                :active-hover="true"
                class="cursor-pointer px-50"

                @click.native.prevent.stop="$emit('changeToNext')"
            />
          </div>
        </template>

        <b-tab
            active
            :title="capitalize($tc('supplierInvoice', incomingInvoicesContext.total_results))+ ' ('+incomingInvoicesContext.total_results+')'"
        >
          <b-card-text>
            <suppliers-invoices-view
                :allow-add="false"
                :allow-add-by-upload="false"
                :allow-edit="false"
                :allow-remove="false"
                :allow-payment="false"

                @reloadSuppliersInvoices="getSuppliersInvoices($event)"
            />

          </b-card-text>
        </b-tab>
        <b-tab :title="capitalize($tc('customerInvoice', outgoingInvoicesContext.total_results))+ ' ('+outgoingInvoicesContext.total_results+')'">
          <b-card-text>
            <customer-invoices-view
                :allow-add="false"
                :allow-edit="false"
                :allow-remove="false"
                :allow-payment="false"
                :disabled-filters="['_filter_draft', '_filter_sent']"

                @reloadCustomerInvoices="getCustomerInvoices($event)"
            />

          </b-card-text>
        </b-tab>
        <b-tab :title="capitalize($tc('salary', salariesContext.total_results))+ ' ('+salariesContext.total_results+')'">
          <b-card-text>
            <salaries-view
                :allow-add="false"
                :allow-add-by-upload="false"
                :allow-edit="false"
                :allow-remove="false"
                :allow-payment="false"

                @reloadSalaries="getSalaries($event)"
            />
          </b-card-text>
        </b-tab>
        <b-tab :title="capitalize($tc('socialSecurityContribution', socialSecurityContributionsContext.total_results))+ ' ('+socialSecurityContributionsContext.total_results+')'">
          <b-card-text>
            <charges-view
                :allow-add="false"
                :allow-add-by-upload="false"
                :allow-edit="false"
                :allow-remove="false"
                :allow-payment="false"

                @reloadCharges="getCharges($event)"
            />
          </b-card-text>
        </b-tab>
        <b-tab :title="capitalize($tc('documents',2))">
          <b-card-text>
            <tab-documents
                :month-str="monthStr"
            />
          </b-card-text>
        </b-tab>

      </b-tabs>
    </b-modal>

  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { capitalize }                      from '../../../../../../utils/filter'

import SuppliersInvoicesView from '../../../../../../components/views/SuppliersInvoicesView.vue'
import CustomerInvoicesView  from '../../../../../../components/views/CustomerInvoicesView.vue'
import SalariesView          from '../../../../../../components/views/SalariesView.vue'
import ChargesView           from '../../../../../../components/views/ChargesView.vue'
import TabDocuments          from './TabDocuments.vue'

import moment from 'moment'
import store  from '../../../../../../store'
import useAPI from '../../../../../../utils/useAPI'

export default {
  components: {
    SuppliersInvoicesView,
    CustomerInvoicesView,
    SalariesView,
    ChargesView,
    TabDocuments
  },
  props: {
    monthStr: {
      type: String
    },
    isOpen: {
      type: Boolean,
      default: false
    },
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isOpenLocal = ref(props.isOpen)
    const supplierInvoicesLength = ref(0)
    const isMounted = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const {
      incomingInvoices2,
      incomingInvoicesContext,
      outgoingInvoices2,
      outgoingInvoicesContext,
      salaries2,
      salariesContext,
      socialSecurityContributions2,
      socialSecurityContributionsContext
    } = useAPI()

    const title = computed(() => {
      return capitalize(moment(props.monthStr + '-01').format('MMMM YYYY'))
    })

    const start = computed(() => {
      return moment(props.monthStr + '-01')
    })

    const end = computed(() => {
      return moment(props.monthStr + '-01').endOf('month')
    })

    const commonSupplierInvoiceSearchCriteria = computed(() => {
      return [
        {
          sectionLogicOperator: 'AND',
          sectionFilters: [{
            logicOperator: 'AND',
            operator: 'between',
            field: 'billingDate',
            value: [start.value.format('YYYY-MM-DD'), end.value.format('YYYY-MM-DD')]
          }]
        }
      ]
    })

    const commonCustomerInvoiceSearchCriteria = computed(() => {
      return [
        {
          sectionLogicOperator: 'AND',
          sectionFilters: [{
            logicOperator: 'AND',
            operator: 'between',
            field: 'billingDate',
            value: [start.value.format('YYYY-MM-DD'), end.value.format('YYYY-MM-DD')]
          }]
        },
        {
          sectionLogicOperator: 'AND',
          sectionFilters: [{
            logicOperator: 'AND',
            operator: 'is_not_empty',
            field: 'billNumber',
            }]
        }
      ]
    })

    const commonSalarySearchCriteria = computed(() => {
      // return [
      //   {
      //     sectionLogicOperator: 'AND',
      //     sectionFilters: [{
      //       logicOperator: 'AND',
      //       operator: 'between',
      //       field: 'month',
      //       value: [start.value.format('YYYY-MM-DD'), end.value.format('YYYY-MM-DD')]
      //     }]
      //   }
      // ]

      return [{
        column_name: 'entityBankTransactionReconciliated',
        comparator: 'range',
        search_text_0: start.value.format('YYYY-MM-DD'),
        search_text_1: end.value.format('YYYY-MM-DD'),
      }]
    })

    const commonChargeSearchCriteria = computed(() => {
      // return [
      //   {
      //     sectionLogicOperator: 'AND',
      //     sectionFilters: [{
      //       logicOperator: 'AND',
      //       operator: 'between',
      //       field: 'month',
      //       value: [start.value.format('YYYY-MM-DD'), end.value.format('YYYY-MM-DD')]
      //     }]
      //   }
      // ]

      return [{
        column_name: 'entityBankTransactionReconciliated',
        comparator: 'range',
        search_text_0: start.value.format('YYYY-MM-DD'),
        search_text_1: end.value.format('YYYY-MM-DD'),
      }]
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(() => props.isOpen, val => {
      isOpenLocal.value = val
    })
    watch(isOpenLocal, val => {
      emit('update:isOpen', val)
    })
    watch(() => props.monthStr, val => {
      // console.log(val)
      // if (!isMounted.value) {
      getSuppliersInvoices({ filters: [] })
      getCustomerInvoices({ filters: [] })
      getSalaries({ searchCriteria: [] })
      getCharges({ searchCriteria: [] })
      // isMounted.value = true
      // }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const getSuppliersInvoices = payloadTmp => {
      let payload = JSON.parse(JSON.stringify(payloadTmp))
      payload.filters = concatSearchCriteria(payloadTmp.filters, commonSupplierInvoiceSearchCriteria.value)
      store.dispatch('incomingInvoice2/getIncomingInvoices', payload)
    }

    const getCustomerInvoices = payloadTmp => {
      let payload = JSON.parse(JSON.stringify(payloadTmp))
      payload.filters = concatSearchCriteria(payloadTmp.filters, commonCustomerInvoiceSearchCriteria.value)
      store.dispatch('outgoingInvoice2/getOutgoingInvoices', payload)
    }

    const getSalaries = payloadTmp => {
      let payload = JSON.parse(JSON.stringify(payloadTmp))
      payload.searchCriteria = concatSearchCriteria(payloadTmp.searchCriteria, commonSalarySearchCriteria.value)
      store.dispatch('salary2/getSalaries', payload)
    }

    const getCharges = payloadTmp => {
      let payload = JSON.parse(JSON.stringify(payloadTmp))
      payload.searchCriteria = concatSearchCriteria(payloadTmp.searchCriteria, commonChargeSearchCriteria.value)
      store.dispatch('socialSecurityContribution2/getSocialSecurityContributions', payload)
    }

    const concatSearchCriteria = (criterias1, criterias2) => {
      let criterias = JSON.parse(JSON.stringify(criterias1))

      JSON.parse(JSON.stringify(criterias2)).forEach(c2 => {
        let pos = criterias1.findIndex(c1 => c1.column_name == c2.column_name)

        if (pos != -1) {
          criterias[pos] = c2
        } else {
          criterias.push(c2)
        }
      })
      return criterias
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------

    return {
      // Components
      capitalize,

      // Data
      isOpenLocal,
      supplierInvoicesLength,

      // Computed
      incomingInvoices2,
      incomingInvoicesContext,
      outgoingInvoices2,
      outgoingInvoicesContext,
      salaries2,
      salariesContext,
      socialSecurityContributions2,
      socialSecurityContributionsContext,
      title,

      // Methods
      getSuppliersInvoices,
      getCustomerInvoices,
      getSalaries,
      getCharges,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>