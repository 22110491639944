<template>
  <div>
    <div class="row">

      <!--      Customer receivable-->
      <div class="col-md-6">
        <customer-receivable
            :customer-receivable-indicator="bankAccountsIndicators.customerReceivable"
        />
      </div>

      <!--      Supplier receivable-->
      <div class="col-md-6">
        <supplier-receivable
            :supplier-receivable-indicator="bankAccountsIndicators.supplierReceivable"
        />
      </div>
    </div>

    <!--      Treasury-->
    <div class="row">
      <div class="col-12">
        <treasury
            :treasury="bankAccountsIndicators.cashFlows"
        />
      </div>
    </div>

    <div class="row">
      <!--      Expenses & revenues-->
      <div class="col-md-4">
        <expenses-and-revenues
            :expenses-and-revenues="bankAccountsIndicators.revenuesAndExpenses"
        />
      </div>
      <!--      Expense items-->
      <div class="col-md-4">
        <expense-items
            :expense-items="bankAccountsIndicators.expenseItems"
        />
      </div>
      <!--      Banks-->
      <div class="col-md-4">
        <banks
            :banks="bankAccountsIndicators.banks"
        />
      </div>


    </div>

  </div>
</template>

<script>
import { ref, computed, watch }    from '@vue/composition-api'
import { getCurrentFinancialYear } from '../../../utils/utils'

import useAPI from '../../../utils/useAPI'
import moment from 'moment'

import CustomerReceivable  from './components/CustomerReceivable'
import SupplierReceivable  from './components/SupplierReceivable'
import Treasury            from './components/Treasury'
import ExpensesAndRevenues from './components/expensesAndRevenues/ExpensesAndRevenues.vue'
import ExpenseItems        from './components/ExpenseItems'
import Banks               from './components/Banks'

export default {
  components: {
    CustomerReceivable,
    SupplierReceivable,
    Treasury,
    ExpensesAndRevenues,
    ExpenseItems,
    Banks,
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { bankAccountsIndicators } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { fetchBankAccountsIndicators } = useAPI()

    const getCustomerReceivable = () => {
      fetchBankAccountsIndicators({
        start: getCurrentFinancialYear().start,
        end: moment().endOf('month').format('YYYY-MM-DD'),
      })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    getCustomerReceivable()

    return {
      // Components

      // Data

      // Computed
      bankAccountsIndicators,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>