<template>
  <div>
    <!--          Liaison form xlsx-->
    <document-content
        :document="{
            name: $t('LiaisonForm') + ' (Excel)',
            fileName: monthStr,
            extension: 'xlsx'
            }"
        :is-deletable="false"
        :is-parent-method-priority="true"

        @download="downloadLiaisonForm"
        @click="downloadLiaisonForm"
    />

    <!--          Liaison form PDF-->
    <document-content
        :document="{
            name: $t('LiaisonForm') + ' (PDF)',
            fileName: monthStr,
            extension: 'pdf'
            }"
        :is-deletable="false"
        :is-parent-method-priority="true"

        @download="downloadLiaisonForm('pdf')"
        @click="downloadLiaisonForm('pdf')"
    />

    <!--          Detail expenses by pdf-->
    <document-content
        :document="{
            name: $t('PurchasesMerging'),
            fileName: capitalize($t('purchases:')) + ' ' + monthStr,
            extension: 'pdf'
            }"
        :is-deletable="false"
        :is-parent-method-priority="true"

        @download="downloadDetailPurchases"
        @click="downloadDetailPurchases"
    />

    <!--          Detail sales by pdf-->
    <document-content
        :document="{
            name: $t('SalesMerging'),
            fileName: capitalize($t('sales:')) + ' ' + monthStr,
            extension: 'pdf'
            }"
        :isDeletable="false"
        :isParentMethodPriority="true"

        @download="downloadDetailSales"
        @click="downloadDetailSales"
    />


  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import {capitalize} from '../../../../../../utils/filter'

import DocumentContent from '../../../../../../components/card/components/DocumentContent.vue'
import store from '../../../../../../store'

export default {
  components: {
    DocumentContent,
  },
  props: {
    monthStr: {
      type: String
    },
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const downloadLiaisonForm = (format = 'xlsx') => {
      store.dispatch('document/generateLiaisonForm', { date: props.monthStr, type: format })
    }

    const downloadDetailPurchases = () => {
        store.dispatch('document/generatePdfDetailPurchases', props.monthStr)
    }

    const downloadDetailSales = () => {
      store.dispatch('document/generatePdfDetailSales', props.monthStr)
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data

      // Computed

      // Methods
      downloadLiaisonForm,
      downloadDetailPurchases,
      downloadDetailSales,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>