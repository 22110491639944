var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('document-content',{attrs:{"document":{
          name: _vm.$t('LiaisonForm') + ' (Excel)',
          fileName: _vm.monthStr,
          extension: 'xlsx'
          },"is-deletable":false,"is-parent-method-priority":true},on:{"download":_vm.downloadLiaisonForm,"click":_vm.downloadLiaisonForm}}),_c('document-content',{attrs:{"document":{
          name: _vm.$t('LiaisonForm') + ' (PDF)',
          fileName: _vm.monthStr,
          extension: 'pdf'
          },"is-deletable":false,"is-parent-method-priority":true},on:{"download":function($event){return _vm.downloadLiaisonForm('pdf')},"click":function($event){return _vm.downloadLiaisonForm('pdf')}}}),_c('document-content',{attrs:{"document":{
          name: _vm.$t('PurchasesMerging'),
          fileName: _vm.capitalize(_vm.$t('purchases:')) + ' ' + _vm.monthStr,
          extension: 'pdf'
          },"is-deletable":false,"is-parent-method-priority":true},on:{"download":_vm.downloadDetailPurchases,"click":_vm.downloadDetailPurchases}}),_c('document-content',{attrs:{"document":{
          name: _vm.$t('SalesMerging'),
          fileName: _vm.capitalize(_vm.$t('sales:')) + ' ' + _vm.monthStr,
          extension: 'pdf'
          },"isDeletable":false,"isParentMethodPriority":true},on:{"download":_vm.downloadDetailSales,"click":_vm.downloadDetailSales}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }