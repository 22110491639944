<template>
  <div>

    <b-tabs>
      <b-tab :title="capitalize($tc('document', 1))">
        <iframe
            :src="charge.document.url"
            width="100%"
            height="1192px"
        />
      </b-tab>

      <b-tab :title="capitalize($tc('payment', 2)) + ' ('+charge.paymentRelations.length+')'">
        <h6
            class="mb-50"
        >
          <b>{{ capitalize($tc('payment', 2)) }} :</b>
        </h6>
        <div v-if="charge.paymentRelations.length == 0">
          <i>{{ $t('NoPayment') }}</i>
        </div>
        <b-table
            v-if="charge.paymentRelations.length"
            small
            :fields="paymentsTable.fields"
            :items="paymentsTable.items"
        />

        <!--          </b-col>-->
        <!--        </b-row>-->
      </b-tab>


    </b-tabs>


<!--    <iframe-->
<!--        :src="charge.document.url"-->
<!--        width="100%"-->
<!--        height="1192px"-->
<!--    />-->
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency } from '../../utils/filter'
import i18n from '../../libs/i18n'
import moment from 'moment'

export default {
  components: {},
  props: {
    charge: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const paymentsTable = computed(() => {
      let payments = []
      props.charge.paymentRelations.forEach(pr => {
        let tmp = {}
        tmp[i18n.t('date')] = moment(pr.paiement.date).format('L')
        tmp[i18n.t('paymentMethod')] = i18n.t(pr.paiement.paymentMethod.name)
        tmp[i18n.t('amount')] = currency(pr.amount)
        payments.push(tmp)
      })

      return {
        fields: [i18n.t('date'), i18n.t('paymentMethod'), i18n.t('amount')],
        items: payments
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data

      // Computed
      paymentsTable,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>